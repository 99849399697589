import axios from 'axios'
// =======  preparing to the deplyment  ========
const URL = window.location.hostname === `localhost`
            ? `http://localhost:3069` // 3030 should be replaced with your server port
            : `https://alumni.barcelonacodeschool.com` // it should be replaced with actual domain during the deployment
// =============================================
const customInstance = axios.create ({
  baseURL : URL, 
  headers: {'Accept': 'application/json'}
})

export default customInstance;