import './App.css';
import {useState, useEffect} from 'react'
import axios from './config.js';
import ContentEditable from 'react-contenteditable'
import PlacesAutocomplete from 'react-places-autocomplete';

import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

function Student(props) {
  let [sort, setSort]= useState('down')

  let [student, setStudent] = useState(null)
  let [location, setLocation] = useState('')

  useEffect(()=>{

    let getStudents =async ()=> {
      const response = await axios.get(`/students/student/${props.match.params.id}`)
      setStudent(response.data)
      setLocation(response.data.location)
    }
    getStudents()
  },[])

  let updateDB =async()=> {
    const response = await axios.post(`/students/update/${props.match.params.id}`,
      {student:{
        availability:student.availability,location:student.location,email1:student.email1,portfolio:student.portfolio
      }})
    response && console.log(response)
  }



  let updateStudent = (e,k) => {
    
    let tempS = student
    k == 'location' ? tempS[k] = location : tempS[k] = e.target.value
    console.log(tempS)
    setStudent({...tempS})
    updateDB()
  }

    let updatePort = (e,k) => {
    
    let tempS = student
    tempS[k] = e
    console.log(tempS)
    setStudent({...tempS})
    updateDB()
  }


  let changeStatus = (s) => {
    let tempS = student
    tempS.availability[s]=!tempS.availability[s]
    console.log(tempS)
    setStudent({...tempS})
    updateDB()
  }

  let handleChange = address => {
    // 
    setLocation(address);
  };
  
  let handleSelect = address => {
    
    setLocation(address);
    updateDB()
    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng => console.log('Success', latLng))
    .catch(error => console.error('Error', error));
  };

  return (
    student != null &&
    <div className='student'>
    <h1>{student.name}</h1>
    <hr/>
    <div>
    <p>Graduation date: {(new Date(student.graduationDate)).toLocaleDateString()}</p>
    
    <ContentEditable
              // innerRef={contentEditable}
              html={student.location} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={(e,k)=>updateStudent(e,'location')} // handle innerHTML change
              tagName='p' // Use a custom HTML tag (uses a div by default)
              />
              <PlacesAutocomplete

              fetchData
              value={location}
              onChange={handleChange}
              onSelect={(e,k)=>updateStudent(e,'location')}
              key='AIzaSyB3KrMjFZ_Y5k-s1TE_D5L1R2bvzXW9-mk'
              >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='places'>
                <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
                />
                <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  >
                  <span>{suggestion.description}</span>
                  </div>
                  );
              })}
                </div>
                </div>
                )}
              </PlacesAutocomplete>
              <span><strong>← update location if needed</strong></span>

              <p>Availability: 
              <span className={student.availability.fulltime ? 'green':'crossedOut'} onClick={(status)=>changeStatus('fulltime')}>full-time; </span>
              <span className={student.availability.parttime ? 'green':'crossedOut'} onClick={(status)=>changeStatus('parttime')}>part-time; </span>
              <span className={student.availability.remote ? 'green':'crossedOut'} onClick={(status)=>changeStatus('remote')}>open for remote</span>
              </p>
              <span><strong>↑ click to activate/deactivate each status</strong></span>
              </div>
              <div>
              <ContentEditable
              // innerRef={contentEditable}
              html={student.email1} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={(e,k)=>updateStudent(e,'email1')} // handle innerHTML change
              tagName='p' // Use a custom HTML tag (uses a div by default)
              />
              <span><strong>↑ email is editable</strong></span>
              <ContentEditable
              id='port'
              // innerRef={contentEditable}
              html={student.portfolio} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={(e,k)=>updatePort(document.getElementById("port").innerText,'portfolio')} // handle innerHTML change
              tagName='p' // Use a custom HTML tag (uses a div by default)
              />
              <span><strong>↑ portfolio url is editable</strong></span>
              </div>
              
              </div>
              );
}

export default Student;
