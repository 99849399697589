import './App.css';
import {useState, useEffect} from 'react'
import axios from './config.js';
import { useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {studentsSort, studentsFilter} from './state';

const Students =()=> {

  let sort = useRecoilValue(studentsSort)
  let studentsRole = useRecoilValue(studentsFilter)
  let [students, setStudents] = useState([])
  // let students = useRecoilValue(studentsInState)
  // let setStateStudents = useSetRecoilState(studentsInState)

  useEffect(()=>{
    let getStudents =async ()=> {
      const response = await axios.get('/students/getAll')
      response.data.forEach(s=>s.graduationDate = new Date(s.graduationDate))
      setStudents([...response.data])
      // setStateStudents([...response.data])
    }
    getStudents()
  },[])





  let renderStudents=()=>{
    let ren = {data:students,filter:studentsRole}
    if (ren.filter !== 'all'){
      ren.data=students.filter(s=>s.role[ren.filter])
    }
    sort == 'down' ? ren.data.sort((a,b)=>b.graduationDate-a.graduationDate) :ren.data.sort((a,b)=>a.graduationDate-b.graduationDate )

    return ren.data.map((s,i)=>(
      <div className='student' key={i}>
      <h1>{s.name}</h1>
      <hr/>
      <p>{s.location}</p>
      
      <p>Graduation date: {s.graduationDate.toLocaleDateString()}</p>
      <p>{s.role.developer&s.role.designer ? <span>Developer |</span>:<span>Developer</span>} {s.role.designer && <span>UX Designer</span>}</p>
      <p>Availability: </p>
      <span className={s.availability.fulltime ? 'green':'hide'}>full-time </span>
      <span className={s.availability.parttime ? 'green':'hide'}>part-time </span> 
      <span className={!s.availability.parttime && !s.availability.fulltime ? 'red':'hide'}>not available</span>
      <p>Open for remote job: {s.availability.remote && (s.availability.fulltime || s.availability.parttime) ? <span className='green'>yes</span> : <span className='red'>no</span>} </p>
      
      <p><a href={'mailto:' + s.email1}>email</a> | <a href={s.portfolio}>portfolio</a></p>
      
      </div>
      ))}

      return (
      <>
      {students.length > 0 && renderStudents()}
      </>
      );
    }

    export default Students;
