import React from 'react'
import Helmet from 'react-helmet';
import './footer.css'

class Footer extends React.Component {

	// constructor(props) {
	// 	super(props);
	// }


	render() {

		return (

			<footer className="black">
			<Helmet script={[
				{ "src": "https://www.barcelonacodeschool.com/drift.js"},
				// {'dangerouslySetInnerHTML':`
				// console.log('banana')
				// window.dataLayer = window.dataLayer || [];
				// function gtag(){dataLayer.push(arguments);}
				// gtag('js', new Date());
				// gtag('config', 'AW-856512071');
				// `}
				]}/>

				<div className="grid-1-1-1-1 footerNav">
				<div>
				<h2>In-person courses</h2>

				<a 
				href="https://www.barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-bootcamp/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ JavaScript Full-Stack in-person Bootcamp
				</a>


				<a 
				href="https://www.barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ UX/UI Design in-person Bootcamp
				</a>


				<a 
				href="https://www.barcelonacodeschool.com/programming-with-javascript/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Programming with JavaScript
				</a>


				<a 
				href="https://www.barcelonacodeschool.com/responsive-web-design-with-css3/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Responsive web design / CSS3
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/reactjs-crash-course/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',

				}}
				>
				→ React.js Crash Course
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/backend-development-with-node-express-mongodb/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',

				}}
				>
				→ Back-end development with Express
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/corporate-training/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Corporate training
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/bcn-coding-kids/bcs-young-coders-summer-camp/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Young Coders Summer Camp
				</a>

				</div>

				<div>
				<h2>Online courses</h2>

				<a 
				href="https://www.barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-online-bootcamp/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ JavaScript Full-Stack Online Mentored Bootcamp
				</a>



				<a 
				href="https://www.barcelonacodeschool.com/programming-with-python/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Programming with Python
				</a>


				<a 
				href="https://www.barcelonacodeschool.com/online-short-courses/programming-with-javascript-online-course/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Programming with JavaScript Online Course
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/online-short-courses/react-crash-course-online/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ React.js Online Crash Course
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/online-short-courses/backend-development-with-node-express-mongodb-online/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Back-end development with Express Online course
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/online-short-courses/responsive-web-design-with-css3-online/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Responsive web design with HTML5/CSS3 
				</a>


				</div>

				<div>
				<h2>About us</h2>
				<a 
				href="https://www.barcelonacodeschool.com/calendar/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Calendar
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/contacts/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Contacts
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/about-us/school/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ School
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/job-offer/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Hire developers
				</a>

				<a 
				href="https://www.barcelonacodeschool.com/about-us/testimonials/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Testimonials
				</a>
				<a 
				href="https://www.barcelonacodeschool.com/blog/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Blog
				</a>
				<a  href="https://www.barcelonacodeschool.com/assets/" style={{ 	textDecoration: 'none', 	marginRight: '1em'}}>
				→ Assets
				</a>
				<a 
				href="https://www.barcelonacodeschool.com/about-us/terms-and-conditions/"
				style={{
					textDecoration: 'none',
					marginRight: '1em',
				}}
				>
				→ Terms and Conditions
				</a>
				</div>

				<div>
				<h2>Get in touch</h2>
				<p>→ Come by our campus:</p>
				<p>Carrer de Muntaner, 262, 1-1, 08021 Barcelona, Spain</p>
				<p></p>
				<p>→ Give us a call:</p>
				<a href="tel:+34932092395" style={{marginBottom:'1em'}}>+34 932 092 395</a>
				<p>Mon-Fri, 9:00-18:00</p>
				<a href='https://calendly.com/barcelonacodeschool' style={{marginBottom:'1em'}}>→ Book a meeting/call with us</a>
				<a href='https://calendly.com/barcelonacodeschool/open-house-at-barcelona-code-school?month=2021-02' style={{marginBottom:'1em'}}>→ Open House every Thursday, 18:30</a>
				</div>

				</div>

				<p className="copyrights">Copyright © 2015-{(new Date).getFullYear()} — Barcelona Code School | Upgrade Yourself!</p>
				</footer>
				)}
}

export default Footer
