import './App.css';
// import {useState, useEffect} from 'react'
import Footer from './Footer/index.js'
// import axios from './config.js';
import Students from './Students.js'
// import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Student from './Student.js'
import {studentsSort,studentsFilter} from './state'
import {useSetRecoilState} from 'recoil'

function App() {
  let sorting = useSetRecoilState(studentsSort)
  let filterStudents = useSetRecoilState(studentsFilter) 


  return (
    <Router>



    <div className="App">
    <header>
    <div style={{background: 'white'}}>
    <h1 className="bcs">
    <a href="https://barcelonacodeschool.com/" style={{textDecoration: 'none',marginLeft: '10%'}}>
    Barcelona Code School
    </a>
    </h1>
    </div>
    </header>
    <div className='nav'>
    <NavLink
    exact
    to={"/"}>
    <p>Alumni directory</p>
    </NavLink>

      {/*<p>Sort by graduation date: <span className='sortDate' onClick={()=>setSort('up')}>up ↑</span> | 
      <span className='sortDate' onClick={()=>setSort('down')}>↓ down</span>
    </p>*/}
    <p>Sort by graduation date: <span className='sortDate' onClick={()=>sorting('up')}>↑</span> | 
    <span className='sortDate' onClick={()=>sorting('down')}>↓</span>
    </p>
    <p>Filter: <span onClick={()=>filterStudents('developer')}>developers</span> | <span onClick={()=>filterStudents('designer')}>designers</span> | <span onClick={()=>filterStudents('all')}>all</span></p>
    </div>
    <main>
    <Route exact path="/" component={Students} />
    <Route path="/student/:id" component={Student} />
    </main>
    <Footer/>
    </div>
    </Router>
    );
}

export default App;
