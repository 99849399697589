import { atom } from 'recoil'


export const studentsSort = atom({
  key: 'studentsSort',
  default: 'down',
});

export const studentsFilter = atom({
  key: 'studentsFilter',
  default: 'all',
});